import React from "react";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import { getDrugNameStr } from "@constants/avey/medications";
import Typography from "@mui/material/Typography";
import { useQueryFetchMedicationHistory } from "@redux/actions/avey/medications";
import { useParams } from "react-router-dom";
import MedicationData from "../MedicationData";
import { MedicationHeaderTitle } from "./MedicationHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { code, trade_name, generic_name } = object;
  const tradeName = getDrugNameStr(trade_name);
  const genericName = getDrugNameStr(generic_name);
  return (
    <div>
      <Typography variant="body2" fontWeight="bold" className="ellipsis-1">
        {code}
      </Typography>
      <Typography variant="body2" className="ellipsis-1" fontWeight="light">
        {tradeName}
      </Typography>
      <Typography variant="body2" className="ellipsis-1" fontWeight="light">
        {genericName}
      </Typography>
    </div>
  );
};

export default function MedicationVersionHistory() {
  const { medication__id: id } = useParams();
  const useQueryFnProps = { id };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchMedicationHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={MedicationData}
      VersionHistoryObjectTitleComponent={MedicationHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
