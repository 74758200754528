import React from "react";
import CustomDividedList from "@components/Custom/CustomDividedList";
import CustomLabeledText from "@components/Custom/CustomLabeledText";
import Stack from "@mui/material/Stack";

export default function ClaimSubmissionUploadDataExceptionJson({
  exception_json = {},
}) {
  const { error = "", row_numbers = [] } = exception_json || {};
  const list = (row_numbers || []).map((row_number) => ({
    name: row_number,
    color: "red",
  }));
  const hasList = list.length > 0;

  return (
    <Stack spacing={2}>
      <CustomLabeledText label="Error" value={error || "-"} />
      {hasList && (
        <CustomLabeledText label="Row numbers" useChildren={true}>
          <CustomDividedList list={list} divideAt={null} />
        </CustomLabeledText>
      )}
    </Stack>
  );
}
