import React from "react";
import ConsumableCreateForm from "@components/Authenticated/Configurations/Consumables/Consumable/ConsumableForms/ConsumableCreateForm";
import { useQueryFetchConsumables } from "@redux/actions/avey/consumables";
import { selectorAuthCurrentUserOrganizationSlug } from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import CustomInfiniteAutocomplete from "../CustomInfiniteAutocomplete";
import {
  getConsumableOptionLabel,
  isConsumableOptionEqualToValue,
  renderConsumableOption,
} from "./ConsumableAutocompleteComponents";

export default function ConsumableAutocomplete({
  label = "Consumable",
  error = false,
  value,
  handleChange = () => {},
  wrapperProps = {},
  textFieldProps = {},
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const autocompleteProps = React.useMemo(
    () => ({
      renderOption: renderConsumableOption,
      noOptionsTextProps: {
        label: "consumable",
        handleIsOpen: ({ searchQuery }) => {
          console.log({ searchQuery });
          setIsOpen(true);
        },
      },
    }),
    []
  );
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const useQueryFnProps = {
    filters: {
      time_deleted__isnull: true,
      newer_version__isnull: true,
    },
    eesFilters: { submitter_organization__slug },
    useInfiniteWrapper: true,
  };

  return (
    <>
      <CustomInfiniteAutocomplete
        label={label}
        error={error}
        wrapperProps={wrapperProps}
        textFieldProps={textFieldProps}
        autocompleteProps={autocompleteProps}
        useQueryFn={useQueryFetchConsumables}
        useQueryFnProps={useQueryFnProps}
        getOptionLabel={getConsumableOptionLabel}
        isOptionEqualToValue={isConsumableOptionEqualToValue}
        value={value}
        handleChange={handleChange}
      />
      <ConsumableCreateForm
        parentIsOpen={isOpen}
        parentSetIsOpen={setIsOpen}
        handleSelect={handleChange}
        withCustomButton={false}
      />
    </>
  );
}
