import React from "react";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import Typography from "@mui/material/Typography";
import { useQueryFetchUnitHistory } from "@redux/actions/avey/units";
import { useParams } from "react-router-dom";
import UnitData from "./UnitData";
import { UnitHeaderTitle } from "./UnitHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { name } = object;
  return (
    <Typography variant="body2" fontWeight="bold" className="ellipsis-1">
      {name}
    </Typography>
  );
};

export default function UnitVersionHistory() {
  const { unit__id: id } = useParams();
  const useQueryFnProps = { id };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchUnitHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={UnitData}
      VersionHistoryObjectTitleComponent={UnitHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
