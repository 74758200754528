import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

export default function CustomGrid({
  rowSpacing = 3,
  columnSpacing = 6,
  children,
  gridItemProps = {},
  ...other
}) {
  const childrenArray = React.Children.toArray(children);

  return (
    <div>
      <Grid2
        container
        rowSpacing={rowSpacing}
        columnSpacing={columnSpacing}
        {...other}
      >
        {childrenArray.map((child, index) => (
          <Grid2 key={index}>{child}</Grid2>
        ))}
      </Grid2>
    </div>
  );
}
