import React from "react";
import InsuranceOrganizationActionDialogTitle from "@components/Authenticated/Configurations/Configurations/InsuranceOrganizationsConfigurations/InsuranceOrganizationActionDialogTitle";
import CustomActionDialog from "@components/Custom/CustomActionDialog";
import CustomInfiniteGrid from "@components/Custom/CustomDataGrid/CustomInfiniteGrid";
import CustomListItemCollapse from "@components/Custom/CustomListItemCollapse";
import CustomProgressBar from "@components/Custom/CustomProgressBar";
import CustomStatusBox from "@components/Custom/CustomStatusBox";
import { CustomSummaryCard } from "@components/Custom/CustomSummaryCards";
import {
  CLAIM_SUBMISSION_UPLOAD_STATUS_BOX_PROPS_MAPPER,
  CLAIM_SUBMISSION_UPLOAD_STATUS_COLOR_MAPPER,
  CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING,
  CLAIM_SUBMISSION_UPLOAD_STATUS_TEXT_MAPPER,
} from "@constants/claims/claim-submission-uploads";
import { parseTime, parseTimestamp } from "@helpers/parse/time";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import {
  cancelClaimSubmissionUpload,
  updateClaimSubmissionUploadObjectTSQ,
  useQueryFetchClaimSubmissionUploads,
} from "@redux/actions/claims/claim-submission-uploads";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import {
  getClaimSubmissionUploadSnackbarKey,
  parseClaimSubmissionUploadSnackbar,
} from "../ClaimSubmissionUpload/ClaimSubmissionUploadSnackbar";

export default function ClaimSubmissionUploadsPending({ spacing = 2 }) {
  const { organization__slug, claim_submission__id } = useParams();
  const infiniteQuery = useQueryFetchClaimSubmissionUploads({
    sortQuery: "-time_created",
    filters: {
      organization__slug,
      claim_submission__id,
      preprocessing_status: CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING,
    },
  });

  const { isLoading, data } = infiniteQuery;

  const hasData = Boolean((data?.pages?.[0]?.results || []).length !== 0);

  return (
    <Collapse in={hasData && !isLoading}>
      <Box mt={spacing}>
        <CustomListItemCollapse
          primary="Active uploads"
          wrapperProps={{ width: "max-content" }}
        >
          <CustomInfiniteGrid
            customLoadingIndicator={null}
            displayNoResultsFound={false}
            infiniteQuery={infiniteQuery}
            ObjectComponent={ClaimSubmissionUploadPending}
            objectComponentProps={{ organization__slug, claim_submission__id }}
            gridContainerProps={{ spacing: 2 }}
            gridItemProps={{ xs: 12, md: 6, lg: 4 }}
          />
        </CustomListItemCollapse>
      </Box>
    </Collapse>
  );
}

const ClaimSubmissionUploadPending = ({
  object,
  organization__slug,
  claim_submission__id,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const {
    id,
    time_created,
    num_claims,
    num_claims_created,
    preprocessing_status,
  } = object;
  const title = parseTimestamp({ value: time_created });
  const fullTime = parseTime({ value: time_created });
  const color =
    CLAIM_SUBMISSION_UPLOAD_STATUS_COLOR_MAPPER[preprocessing_status];

  const handleIsOpen = (e) => {
    e?.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsProcessing(true);
    cancelClaimSubmissionUpload({
      id,
      filters: { organization__slug, claim_submission__id },
    })
      .then(({ payload }) => {
        const { instance } = payload;
        updateClaimSubmissionUploadObjectTSQ({ object: instance });
        setIsOpen(false);
        closeSnackbar(
          getClaimSubmissionUploadSnackbarKey({
            id,
            preprocessing_status: CLAIM_SUBMISSION_UPLOAD_STATUS_PENDING,
          })
        );
        enqueueSnackbar(parseClaimSubmissionUploadSnackbar(instance));
      })
      .catch(({ payload }) =>
        enqueueSnackbar({ variant: "error", message: payload })
      )
      .finally(() => setIsProcessing(false));
  };

  return (
    <>
      <CustomSummaryCard
        title={title}
        titleProps={{
          variant: "body2",
          color: "textPrimary",
          className: "ellipsis-2",
          title: fullTime,
        }}
        headerProps={{ spacing: 2 }}
        wrapperProps={{ spacing: 1, py: 2 }}
        useChildren={true}
        moreActions={
          <MenuItem onClick={handleIsOpen}>{"Cancel upload"}</MenuItem>
        }
        action={
          <Stack spacing={1} direction="row" alignItems="center">
            <CustomStatusBox
              status={preprocessing_status}
              textMapper={CLAIM_SUBMISSION_UPLOAD_STATUS_TEXT_MAPPER}
              propsMapper={CLAIM_SUBMISSION_UPLOAD_STATUS_BOX_PROPS_MAPPER}
            />
            <CustomProgressBar
              color={color}
              nominator={num_claims_created}
              denominator={num_claims}
            />
          </Stack>
        }
      />
      <CustomActionDialog
        isOpen={isOpen}
        isProcessing={isProcessing}
        TitleComponent={InsuranceOrganizationActionDialogTitle}
        titleComponentProps={{ title: "Cancel upload" }}
        subtitleComponentProps={{
          title: "Are you sure you wish to cancel this upload?",
        }}
        handleClose={handleIsOpen}
        handleSubmit={handleSubmit}
        isBodyEmpty={true}
        yesText="Yes, I'm sure"
        noText="No, go back"
        yesButtonProps={{ color: "secondary" }}
        noButtonProps={{ color: "primary" }}
      />
    </>
  );
};
