import React from "react";
import {
  getClaimProcedureSectionId,
  getClaimSymptomsSectionId,
} from "@constants/claims/claim-evaluation-results";
import Typography from "@mui/material/Typography";
import ClaimEvaluationResultLink from "../ClaimEvaluationResultLink";

const ICD_CODE_KEY = "{icd_code}";
const INTERNAL_CODES_KEY = "{internal_codes}";
const ERROR_MESSAGE_KEY = "{error_message}";

export default function ClaimSymptomEvaluationResultContext({
  result = {},
  isCreateOrUpdateMode = false,
}) {
  const {
    claim_symptom,
    claim_procedures,
    icd_code,
    description,
    error_message,
  } = result || {};
  const { icd } = claim_symptom || {};
  const { code, description: icdDescription } = icd || {};

  return {
    [ERROR_MESSAGE_KEY]: (
      <Typography variant="body2" display="inline">
        {error_message}
      </Typography>
    ),
    [ICD_CODE_KEY]: (
      <ClaimEvaluationResultLink
        to={getClaimSymptomsSectionId({ isCreateOrUpdateMode })}
        value={icd_code || code}
        title={description || icdDescription}
      />
    ),
    [INTERNAL_CODES_KEY]: (
      <span>
        {claim_procedures.map((claim_procedure, index) => {
          const { id, organization_bundle } = claim_procedure;
          const { internal_code, description } = organization_bundle || {};
          const href = getClaimProcedureSectionId({
            isCreateOrUpdateMode,
            id,
          });
          const delimiter = index < claim_procedures.length - 1 ? ", " : "";
          return (
            <React.Fragment key={id}>
              <ClaimEvaluationResultLink
                to={href}
                value={internal_code}
                title={description}
              />
              {delimiter}
            </React.Fragment>
          );
        })}
      </span>
    ),
  };
}
