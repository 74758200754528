import React from "react";
import CustomGrid from "@components/Custom/CustomLayout/CustomGrid";
import CustomTooltip from "@components/Custom/CustomTooltip";
import {
  CLAIM_EVALUATION_RESULT_ICON_MAPPER,
  CLAIM_EVALUATION_RESULT_STATUS_ALERT,
  CLAIM_EVALUATION_RESULT_STATUS_FAILURE,
} from "@constants/claims/claim-evaluation-results";
import { getIntOrFloat } from "@helpers/parse/numbers";
import Badge from "@mui/material/Badge";
import { DeleteIcon, LockIcon } from "@rimads/assets";
import { AVEY_GREY_DARK } from "@styles/theme";
import pluralize from "pluralize";

const badgeFontSize = 12;
const iconDimension = 20;

const IconComponentWrapper = ({ IconComponent, primary_fill = undefined }) => (
  <div style={{ display: "grid", alignItems: "center" }}>
    <IconComponent dimension={iconDimension} primary_fill={primary_fill} />
  </div>
);

export default function ClaimStatusAdditionalTags({
  num_failure_evaluation_results = 0,
  num_alert_evaluation_results = 0,
  time_deleted = null,
  time_locked = null,
}) {
  const sum = num_failure_evaluation_results + num_alert_evaluation_results;

  if (sum === 0) return null;

  const counts = [
    {
      status: CLAIM_EVALUATION_RESULT_STATUS_FAILURE,
      count: num_failure_evaluation_results,
      label: "Failed test",
    },
    {
      status: CLAIM_EVALUATION_RESULT_STATUS_ALERT,
      count: num_alert_evaluation_results,
      label: "Alerted test",
    },
  ]
    .filter(({ count }) => count > 0)
    .map(({ status, ...otherProps }) => ({
      ...otherProps,
      IconComponent: CLAIM_EVALUATION_RESULT_ICON_MAPPER[status],
    }));

  const tags = [
    {
      value: time_deleted,
      title: (
        <>
          <b>{"This claim is deleted"}</b>
          <br />
          {"No more changes can be made to this claim."}
        </>
      ),
      IconComponent: DeleteIcon,
      primary_fill: AVEY_GREY_DARK,
    },
    {
      value: time_locked,
      title: (
        <>
          <b>{"This claim is locked"}</b>
          <br />
          {"No more changes can be made to this claim."}
        </>
      ),
      IconComponent: LockIcon,
      primary_fill: AVEY_GREY_DARK,
    },
  ].filter(({ value }) => Boolean(value));

  return (
    <CustomGrid rowSpacing={1} columnSpacing={2} alignItems="center">
      {counts.map(({ count, label, IconComponent }, index) => (
        <CustomTooltip
          title={
            <>
              <b>{getIntOrFloat(count)}</b> {pluralize(label, count, false)}
            </>
          }
          key={index}
        >
          <Badge
            color="info"
            badgeContent={count}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            slotProps={{
              badge: {
                className: "elevated-8 gradient-to-top",
                style: {
                  backgroundColor: "transparent",
                  padding: 4,
                  minWidth: 0,
                  fontSize: badgeFontSize,
                },
              },
            }}
          >
            <IconComponentWrapper IconComponent={IconComponent} />
          </Badge>
        </CustomTooltip>
      ))}
      {tags.map(({ title, IconComponent, primary_fill }) => (
        <CustomTooltip title={title}>
          <IconComponentWrapper
            IconComponent={IconComponent}
            primary_fill={primary_fill}
          />
        </CustomTooltip>
      ))}
    </CustomGrid>
  );
}
