import React from "react";
import CustomVersionHistory from "@components/Custom/CustomVersionHistory";
import Typography from "@mui/material/Typography";
import { useQueryFetchIdentityTypeHistory } from "@redux/actions/avey/identity-types";
import { useParams } from "react-router-dom";
import IdentityTypeData from "./IdentityTypeData";
import { IdentityTypeHeaderTitle } from "./IdentityTypeHeader";

const VersionHistoryObjectTabComponent = ({ object }) => {
  const { name } = object;
  return (
    <Typography variant="body2" fontWeight="bold" className="ellipsis-1">
      {name}
    </Typography>
  );
};

export default function IdentityTypeVersionHistory() {
  const { identity_type__id: id } = useParams();
  const useQueryFnProps = { id };

  return (
    <CustomVersionHistory
      useQueryFn={useQueryFetchIdentityTypeHistory}
      useQueryFnProps={useQueryFnProps}
      VersionHistoryObjectDataComponent={IdentityTypeData}
      VersionHistoryObjectTitleComponent={IdentityTypeHeaderTitle}
      VersionHistoryObjectTabComponent={VersionHistoryObjectTabComponent}
    />
  );
}
