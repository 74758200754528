import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const StyledToolTip = styled(
  ({ className, bgColor, padding, elevation, borderRadius, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme, padding, elevation = 8, borderRadius = 6, bgColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: bgColor || theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[elevation],
    fontSize: 12,
    borderRadius,
    padding,
  },
}));

export default function CustomTooltip({
  title = "",
  children,
  className = "button",
  wrapperProps = {},
  tooltipProps = {},
  useCustomTooltip = true,
}) {
  if (!Boolean(title) || !useCustomTooltip)
    return <div {...wrapperProps}>{children}</div>;

  return (
    <StyledToolTip title={title} {...tooltipProps}>
      <span className={className} {...wrapperProps}>
        {children}
      </span>
    </StyledToolTip>
  );
}
