import React from "react";
import ClaimSubmitterCreateClaimFormLink from "@components/Authenticated/ClaimSubmissions/Claims/Claim/Claim/ClaimSubmitterCreateClaimForm/ClaimSubmitterCreateClaimFormLink";
import CustomAddOrUploadWrapper from "@components/Custom/CustomAddOrUploadWrapper";
import { getClaimSubmissionUploadHref } from "@constants/static/routing";
import { useParams } from "react-router-dom";
import ClaimSubmissionUploadForm from "../../ClaimSubmissionUploads/ClaimSubmissionUpload/ClaimSubmissionUploadForm";

export default function ClaimsCreateForms() {
  const { claim_submission__id, organization__slug } = useParams();
  const uploadHistoryHref = getClaimSubmissionUploadHref({
    claim_submission__id,
    organization__slug,
  });
  return (
    <CustomAddOrUploadWrapper
      label="Add claims"
      uploadHistoryHref={uploadHistoryHref}
      useUploadForm={false}
    >
      <ClaimSubmitterCreateClaimFormLink isMenuItem={true} />
      <ClaimSubmissionUploadForm isMenuItem={true} />
    </CustomAddOrUploadWrapper>
  );
}
