import React from "react";
import { CLAIM_EVALUATION_RESULT_ICON_MAPPER } from "@constants/claims/claim-evaluation-results";
import {
  CLAIM_SECTION_DIAGNOSES,
  CLAIM_SECTION_MEDICATIONS,
  CLAIM_SECTION_ORGANIZATION_PATIENT,
  CLAIM_SECTION_PROCEDURES,
  CLAIM_SECTION_SYMPTOMS,
  CLAIM_SECTION_VISIT_DETAILS,
  CLAIM_SECTION_WIDE,
} from "@constants/claims/claims";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ClaimDiagnosisEvaluationResultContext from "./ClaimDiagnosisEvaluationResultContext";
import ClaimEvaluationResultContext from "./ClaimEvaluationResultContext";
import ClaimMedicationEvaluationResultContext from "./ClaimMedicationEvaluationResultContext";
import ClaimProcedureEvaluationResultContext from "./ClaimProcedureEvaluationResultContext";
import ClaimSymptomEvaluationResultContext from "./ClaimSymptomEvaluationResultContext";

export default function ClaimEvaluationResultMessages({
  type,
  isCreateOrUpdateMode,
  results = [],
}) {
  return (
    <Stack spacing={1}>
      {results.map((result, index) => {
        const { icon, message } = parseClaimEvaluationResult({
          type,
          isCreateOrUpdateMode,
          result,
        });
        return (
          <Stack spacing={1} direction="row" alignItems="center" key={index}>
            {icon}
            {message}
          </Stack>
        );
      })}
    </Stack>
  );
}

const CONTEXT_FN_MAPPER = {
  [CLAIM_SECTION_WIDE]: ClaimEvaluationResultContext,
  [CLAIM_SECTION_VISIT_DETAILS]: ClaimEvaluationResultContext,
  [CLAIM_SECTION_SYMPTOMS]: ClaimSymptomEvaluationResultContext,
  [CLAIM_SECTION_DIAGNOSES]: ClaimDiagnosisEvaluationResultContext,
  [CLAIM_SECTION_PROCEDURES]: ClaimProcedureEvaluationResultContext,
  [CLAIM_SECTION_MEDICATIONS]: ClaimMedicationEvaluationResultContext,
  [CLAIM_SECTION_ORGANIZATION_PATIENT]: () => ({}),
};

const parseClaimEvaluationResult = ({
  type,
  isCreateOrUpdateMode = false,
  result = {},
}) => {
  let context = {};
  const { status, message_template_display } = result;
  const getContextFn = CONTEXT_FN_MAPPER[type];
  if (getContextFn) context = getContextFn({ result, isCreateOrUpdateMode });
  const IconComponent = CLAIM_EVALUATION_RESULT_ICON_MAPPER[status];
  const message = formatClaimEvaluationResultMessage({
    message_template_display,
    context,
  });
  return { icon: <IconComponent />, message };
};

const formatClaimEvaluationResultMessage = ({
  message_template_display = "",
  context = {},
}) => (
  <div>
    {message_template_display?.split(" ")?.map((word, index) => (
      <span key={index}>
        {context[word] || (
          <Typography variant="body2" display="inline">
            {` ${word} `}
          </Typography>
        )}
      </span>
    ))}
  </div>
);
