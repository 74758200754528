import React from "react";
import Stack from "@mui/material/Stack";
import ClaimsGrid from "../../Claims/Claims/ClaimsGrid";
import ClaimsNoResults from "../../Claims/Claims/ClaimsNoResults";
import ClaimSubmissionUploadsPending from "../../ClaimSubmissionUploads/ClaimSubmissionUploads/ClaimSubmissionUploadsPending";
import ClaimSubmissionClaimsSummary from "./ClaimSubmissionClaimsSummary";

const spacing = 2;

export default function ClaimsSubmissionBody({ object }) {
  const { claim_submission_summary, latest_claim_submission_upload_id } =
    object;
  const { num_claims } = claim_submission_summary || { has_claims: false };
  const hasContent =
    Boolean(num_claims) || Boolean(latest_claim_submission_upload_id);

  if (hasContent)
    return (
      <Stack spacing={spacing}>
        <div>
          <ClaimSubmissionClaimsSummary
            claim_submission_summary={claim_submission_summary}
          />
          <ClaimSubmissionUploadsPending spacing={spacing} />
        </div>
        <ClaimsGrid />
      </Stack>
    );

  return <ClaimsNoResults />;
}
