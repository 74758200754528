import React from "react";
import { ModelTypeContextProvider } from "@components/ContextProviders/model-type-context-provider";
import CustomContextSearchField from "@components/Custom/CustomContextSearchField";
import CustomInfiniteDataGrid from "@components/Custom/CustomDataGrid/CustomInfiniteDataGrid";
import CustomFilters from "@components/Custom/CustomFilters";
import { ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES } from "@constants/organizations/organization-bundles";
import { MODEL_TYPE_ORGANIZATION_BUNDLE } from "@constants/static/globals";
import useFetchOrganizationAgreement from "@helpers/hooks/useFetchObjects/useFetchOrganizationAgreement";
import Grid from "@mui/material/Grid";
import { useQueryFetchOrganizationBundles } from "@redux/actions/organizations/organization-bundles";
import {
  selectorAuthCurrentUserOrganizationCurrency,
  selectorAuthCurrentUserOrganizationSlug,
  selectorAuthHasAdminPrivileges,
} from "@redux/selectors/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ConfigurationsBulkExportActions from "../../Configurations/ConfigurationsBulkExport/ConfigurationsBulkExport/ConfigurationsBulkExportActions";
import OrganizationAgreementFollowupDiscountForm from "../../OrganizationAgreements/OrganizationAgreement/OrganizationAgreementForms/OrganizationAgreementFollowupDiscount/OrganizationAgreementFollowupDiscountForm";
import OrganizationAgreementSecondProcedureDiscountForm from "../../OrganizationAgreements/OrganizationAgreement/OrganizationAgreementForms/OrganizationAgreementSecondProcedureDiscount/OrganizationAgreementSecondProcedureDiscountForm";
import OrganizationBundlesCreateForms from "./OrganizationBundlesCreateForms";
import {
  formatFilters,
  getFilterOptions,
  initialFilterState,
} from "./OrganizationBundlesFilters";
import OrganizationBundlesGridProps from "./OrganizationBundlesGridProps";

const initialSortModel = [{ sort: "desc", field: "time_updated" }];

export default function OrganizationBundlesGrid({ bundle_type }) {
  const { organization__slug: processor_organization__slug } = useParams();
  const submitter_organization__slug = useSelector(
    selectorAuthCurrentUserOrganizationSlug
  );
  const currency = useSelector(selectorAuthCurrentUserOrganizationCurrency);
  const { data: organization_agreement } = useFetchOrganizationAgreement();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filters, setFilters] = React.useState({});
  const hasAdminPrivileges = useSelector(selectorAuthHasAdminPrivileges);
  const useQueryFnProps = {
    searchQuery,
    filters: {
      newer_version__isnull: true,
      submitter_organization__slug,
      processor_organization__slug,
      bundle_type,
      ...filters,
    },
  };
  const filterOptions = React.useMemo(
    () => getFilterOptions({ bundle_type, currency }),
    [bundle_type, currency]
  );
  const dataGridProps = React.useMemo(
    () =>
      OrganizationBundlesGridProps({
        processor_organization__slug,
        bundle_type,
      }),
    [processor_organization__slug, bundle_type]
  );
  const isProceduresAndConsumables =
    bundle_type === ORGANIZATION_BUNDLE_TYPE_PROCEDURES_CONSUMABLES;

  return (
    <ModelTypeContextProvider
      model_type={MODEL_TYPE_ORGANIZATION_BUNDLE}
      bundle_type={bundle_type}
    >
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        {isProceduresAndConsumables && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <OrganizationAgreementSecondProcedureDiscountForm
                  object={organization_agreement}
                />
              </Grid>
              <Grid item>
                <OrganizationAgreementFollowupDiscountForm
                  object={organization_agreement}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item flexGrow={1}>
          <CustomContextSearchField setSearchQuery={setSearchQuery} />
        </Grid>
        <Grid item>
          <CustomFilters
            initialFilterState={initialFilterState}
            filterOptions={filterOptions}
            formatFilters={formatFilters}
            setFilters={setFilters}
          />
        </Grid>
        {hasAdminPrivileges && (
          <>
            <Grid item>
              <OrganizationBundlesCreateForms bundle_type={bundle_type} />
            </Grid>
            <Grid item>
              <ConfigurationsBulkExportActions
                useQueryFnProps={useQueryFnProps}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <CustomInfiniteDataGrid
            useQueryFn={useQueryFetchOrganizationBundles}
            useQueryFnProps={useQueryFnProps}
            dataGridProps={dataGridProps}
            initialSortModel={initialSortModel}
          />
        </Grid>
      </Grid>
    </ModelTypeContextProvider>
  );
}
